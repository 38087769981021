import React, { useCallback, useContext } from "react";
import { SceneDesignerContext } from "../../contexts/SceneDesignerContext";
import { makeRandomId } from "../../helpers/helpers";
import { Animation } from "../../models/StillImageConfiguration";
import { SceneAnimationDTO } from "../../openapi/requests";
import "./AnimationCreator.scss";
import { AnimationVideoPlayer } from "./AnimationSelector";
import { AuthContext } from "../../contexts/AuthContext";

interface Props {
    onCancel: () => void;
    onCreated: (animationName: string) => void;
}

const AnimationCreator: React.FC<Props> = ({ onCancel, onCreated }) => {
    const { template, setConfiguration, configuration } = useContext(SceneDesignerContext);
    const { hasRole } = useContext(AuthContext);

    const handleCreateClick = useCallback((aspect: number) => {
        const animation: Animation = {
            Name: makeRandomId(8),
            Shots: [],
            Title: 'My animation',
            Variants: [],
            Aspect: aspect,
        }

        setConfiguration({
            ...configuration,
            animations: [...configuration.animations, animation]
        });

        onCreated(animation.Name);
    }, [configuration, onCreated, setConfiguration]);

    const handleTemplateClick = useCallback((sceneAnimation: SceneAnimationDTO) => {

        const animation: Animation = {
            Name: makeRandomId(8),
            Shots: sceneAnimation.shots.map(e => {
                const cam = template!.scene.cameras.find(c => c.id === e.cameraId);
                return ({
                    CameraName: cam?.cameraName ?? '',
                    EndFrame: e.endTimestamp ?? cam?.animationEnd ?? 0,
                    StartFrame: e.startTimestamp ?? cam?.animationStart ?? 0
                });
            }),
            Title: sceneAnimation.title,
            Variants: [],
            Aspect: template!.scene.cameras.find(c => c.id === sceneAnimation.shots[0].cameraId)?.aspectRatio ?? 1,
        }

        setConfiguration({
            ...configuration,
            animations: [...configuration.animations, animation]
        });

        onCreated(animation.Name);
    }, [configuration, onCreated, setConfiguration, template]);

    let animations = template?.scene.animations;

    if (!hasRole("Cadesign")) {
        animations = animations?.filter(e => e.isEnabled);
    }

    return (
        <div className="AnimationCreator">
            <div>
                <h3>Create your own</h3>
                <div className="formats">
                    <button className='animated' onClick={() => handleCreateClick(1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                            <g clipPath="url(#clip0_1395_744)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33ZM20.6406 18.5117C18.375 20.2109 17.1836 22.3203 16.8906 24H16.0703C15.9336 23.1602 15.5625 22.2422 14.9375 21.2461C13.6875 19.2734 11.168 17.3398 9 16.8906V16.0703C10.0742 15.8359 11.1289 15.3477 12.1445 14.625C14.2148 13.1602 15.7383 10.9727 16.0703 9H16.8906C17.0859 10.0352 17.5352 11.0312 18.2383 12.0469C19.625 14.0586 21.793 15.543 24 16.0703V16.8906C22.8867 17.125 21.7539 17.6719 20.6406 18.5117Z" fill="#3D3D3D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1395_744">
                                    <rect width="33" height="33" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        Square
                    </button>
                    <button className='animated' onClick={() => handleCreateClick(1.429)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                            <g clipPath="url(#clip0_1395_744)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33ZM20.6406 18.5117C18.375 20.2109 17.1836 22.3203 16.8906 24H16.0703C15.9336 23.1602 15.5625 22.2422 14.9375 21.2461C13.6875 19.2734 11.168 17.3398 9 16.8906V16.0703C10.0742 15.8359 11.1289 15.3477 12.1445 14.625C14.2148 13.1602 15.7383 10.9727 16.0703 9H16.8906C17.0859 10.0352 17.5352 11.0312 18.2383 12.0469C19.625 14.0586 21.793 15.543 24 16.0703V16.8906C22.8867 17.125 21.7539 17.6719 20.6406 18.5117Z" fill="#3D3D3D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1395_744">
                                    <rect width="33" height="33" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        Landscape
                    </button>
                    <button className='animated' onClick={() => handleCreateClick(0.700)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                            <g clipPath="url(#clip0_1395_744)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33ZM20.6406 18.5117C18.375 20.2109 17.1836 22.3203 16.8906 24H16.0703C15.9336 23.1602 15.5625 22.2422 14.9375 21.2461C13.6875 19.2734 11.168 17.3398 9 16.8906V16.0703C10.0742 15.8359 11.1289 15.3477 12.1445 14.625C14.2148 13.1602 15.7383 10.9727 16.0703 9H16.8906C17.0859 10.0352 17.5352 11.0312 18.2383 12.0469C19.625 14.0586 21.793 15.543 24 16.0703V16.8906C22.8867 17.125 21.7539 17.6719 20.6406 18.5117Z" fill="#3D3D3D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1395_744">
                                    <rect width="33" height="33" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        Portrait
                    </button>
                </div>
            </div>
            <div>
                <h3>Or choose a preset</h3>
                <div className="videos">
                    {animations?.map(animation => <div key={animation.id} className="video" onClick={() => handleTemplateClick(animation)}>
                        <AnimationVideoPlayer name={animation.name} />
                        <h2>{animation.title} {!animation.isEnabled && <span>not enabled for clients!</span>}</h2>
                    </div>)}
                </div>
            </div>
        </div>
    )
};

export default AnimationCreator;