import { Checkbox, Drawer, Input, message, Select, Space, Image, Empty, Divider } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'antd';
import { DeleteOutlined, MinusCircleOutlined, PlusOutlined, PushpinOutlined } from '@ant-design/icons';
import QueryField from '../Inputs/QueryField';
import Hotspots from './configuration/hotspots/Hotspots';
import ImageFileUpload from '../Inputs/ImageFileUpload';
import { useInternalClientServiceGetInternalClient, useInternalTagServiceGetInternalTag, useSceneServiceDeleteSceneRenderings, useSceneServiceGetSceneRenderings, useSceneServiceGetSceneRenderingsKey, useSceneServicePutScene } from '../../../openapi/queries';
import { SceneCameraDTO, SceneViewDTO } from '../../../openapi/requests';
import { BASE_PATH } from '../../..';
import { AssetApprovalStatus, AssetApprovalStatusLabels } from '../../../models/enums';
import { useQueryClient } from '@tanstack/react-query';
const { TextArea } = Input;

const ConfigureCameraInput: React.FC<{ camera: SceneCameraDTO | undefined, scene: SceneViewDTO | undefined }> = ({ camera, scene }) => {
    const [isShowHotspots, setIsShowHotspots] = useState(false);

    const onConfigurationChanged = () => {
        setIsShowHotspots(false)
    }

    const onShowHotspots = () => {
        setIsShowHotspots(true)
    }

    return (
        <Space>
            <Button title='Hotspots' onClick={() => onShowHotspots()}><PushpinOutlined /></Button>
            <ImageFileUpload size={100} action={`${BASE_PATH}/camera/${camera?.id}/image`} title='thumbnail' previewPath={`${BASE_PATH}/camera/${camera?.id}/image`} />
            {camera?.isAnimated && <ImageFileUpload size={100} action={`${BASE_PATH}/camera/${camera?.id}/animationpreview`} title='animation' isVideo previewPath={`${BASE_PATH}/camera/${camera?.id}/animationpreview`} />}
            {isShowHotspots ? <Hotspots camera={camera} scene={scene} onSave={onConfigurationChanged} /> : null}
        </Space>
    )
}


const EditScene: React.FC<{ scene?: SceneViewDTO, isOpen: boolean, onClose: () => void }> = ({ scene, isOpen, onClose }) => {
    const queryClient = useQueryClient()

    const { data: clients } = useInternalClientServiceGetInternalClient({}, undefined, { enabled: isOpen });
    const { data: alltags } = useInternalTagServiceGetInternalTag({}, undefined, { enabled: isOpen });
    const { data: renderings, refetch: refetchRenderings } = useSceneServiceGetSceneRenderings({ key: scene?.id ?? 0 }, undefined, { enabled: isOpen && scene !== undefined });
    const { mutate: deleteRendering, isPending: deletingRendering } = useSceneServiceDeleteSceneRenderings({ onSuccess: () => queryClient.invalidateQueries({ queryKey: [useSceneServiceGetSceneRenderingsKey] }) });
    const [form] = Form.useForm<SceneViewDTO>();
    const [messageApi, contextHolder] = message.useMessage();
    const { mutateAsync, isPending } = useSceneServicePutScene();
    const clientId = Form.useWatch('clientId', form);
    const cameras = Form.useWatch('cameras', form);

    const tags = useMemo(() => {
        if (alltags) {
            const tags = Array.from(new Set(alltags.value.map(e => e.value)));
            tags.sort();
            return tags;
        }
        return [];
    }, [alltags]);

    const onSubmit = () => {
        form.submit();
    }

    const onFinish = () => {
        if (scene) {
            const values = form.getFieldsValue(true) as SceneViewDTO;

            mutateAsync({ key: scene.id, requestBody: values })
                .then(() => {
                    messageApi.success("Scene updated");
                    onClose();
                }).catch(reason => {
                    messageApi.error(JSON.stringify(reason));
                });
        }
    };

    useEffect(() => {
        if (scene) {
            form.setFieldsValue(scene);
        }
    }, [scene, form]);

    return (
        <>
            {contextHolder}

            <Drawer
                title={`Edit ${scene?.title}`}
                width={720}
                onClose={onClose}
                open={isOpen}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{ required: true }]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item label="Thumbnail">
                        <Space direction='vertical'>
                            <ImageFileUpload previewPath={`${BASE_PATH}/scene/${scene?.id}/thumbnail`} action={`${BASE_PATH}/scene/${scene?.id}/image`} />
                        </Space>
                    </Form.Item>

                    <Form.Item label="Renderings">
                        <Space direction='vertical'>
                            <Space wrap>
                                {renderings?.map((img, i) => <Space align='center' direction='vertical'>
                                    <Image key={img} placeholder="" height={150} src={BASE_PATH + img} />
                                    <Button icon={<DeleteOutlined />} loading={deletingRendering} onClick={() => deleteRendering({ key: scene?.id!, index: i })} />
                                </Space>)}
                                {renderings?.length === 0 && <Empty />}
                            </Space>
                            <ImageFileUpload title="Upload rendering" action={`${BASE_PATH}/scene/${scene?.id}/renderings`} onUpload={() => refetchRenderings()} />
                        </Space>
                    </Form.Item>

                    <Form.Item name="platform" label="Platform">
                        <Input defaultValue="3dsmax" value="3dsmax" />
                    </Form.Item>

                    <Form.Item name="isEnabled" valuePropName="checked">
                        <Checkbox>Enabled</Checkbox>
                    </Form.Item>

                    <Form.Item label="Approval status">
                        <b>{AssetApprovalStatusLabels[(scene?.approvalStatus ?? 0) as AssetApprovalStatus]}</b>
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='title' label="Title">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='filename' label="Filename">
                        <Input />
                    </Form.Item>

                    <Form.Item name='defaultConfigurationSpec' label="Default configuration">
                        <TextArea rows={6} />
                    </Form.Item>

                    <Form.Item name='buildScript' label="Build script">
                        <Input />
                    </Form.Item>

                    <Form.Item name='tags' label="Tags">
                        <Select mode='tags' options={tags.map(e => ({ label: e, value: e }))} />
                    </Form.Item>

                    <Form.Item label='Metadata'>
                        <Form.List name="metadata">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="name" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="value" />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add metadata
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item label='Cameras'>
                        <Form.List name="cameras">
                            {(fields) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <React.Fragment key={`${key}`}>
                                            <Space key={`${key}-inputs`} style={{ display: 'flex' }} align="baseline" >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'label']}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input placeholder="Label" />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'cameraName']}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input readOnly disabled placeholder="Camera name" />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'aspectRatio']}
                                                    style={{ width: 75 }}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input readOnly disabled placeholder='Aspect' type='number' />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'sort']}
                                                    style={{ width: 75 }}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input placeholder="Sort" type='number' />
                                                </Form.Item>

                                                <Form.Item name={[name, 'isEnabled']} valuePropName="checked">
                                                    <Checkbox>Enabled</Checkbox>
                                                </Form.Item>
                                            </Space>
                                            <Space key={`${key}-thumbnails`} style={{ display: 'flex' }} align="baseline" >
                                                <Form.Item shouldUpdate name={name}>
                                                    <ConfigureCameraInput camera={scene?.cameras[key]} scene={scene} />
                                                </Form.Item>
                                            </Space>
                                            <Divider />
                                        </React.Fragment>
                                    ))}
                                </>
                            )}
                        </Form.List>

                    </Form.Item>

                    <Form.Item label='Animations'>
                        <Form.List name="animations">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <React.Fragment key={key}>
                                            <Space style={{ display: 'flex' }} align="baseline">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'title']}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input placeholder="title" />
                                                </Form.Item>

                                                <Form.Item name={[name, 'isEnabled']} valuePropName="checked">
                                                    <Checkbox>Enabled</Checkbox>
                                                </Form.Item>

                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </Space>

                                            <Form.Item label='Shots' style={{ paddingLeft: 50 }}>
                                                <Form.List name={[name, 'shots']}>
                                                    {(fields, { add, remove }) => (
                                                        <>
                                                            {fields.map(({ key, name, ...restField }) => (
                                                                <Space key={key} style={{ display: 'flex' }} align="baseline">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'cameraId']}
                                                                        rules={[{ required: true }]}
                                                                    >
                                                                        <Select style={{ width: '250px' }} options={cameras.filter(e => e.isAnimated).map(e => ({ label: e.label, value: e.id }))} />
                                                                    </Form.Item>
                                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                                </Space>
                                                            ))}
                                                            <Form.Item>
                                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                    Add shot
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </Form.Item>
                                        </React.Fragment>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add animation
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item label='Surfaces'>
                        <Form.List name="surfaces">
                            {(fields) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'label']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="Label" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'objectSelector']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input readOnly disabled placeholder="object selector" />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'sort']}
                                                style={{ width: 75 }}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="Sort" type='number' />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'materialSelectionFilter']}
                                            >
                                                <QueryField placeholder="material filter" type='Material' clientId={clientId} />
                                            </Form.Item>
                                        </Space>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item label='Modifiers'>
                        <Form.List name="modifierTargets">
                            {(fields) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'label']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="Label" />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'modifierSelectionFilter']}
                                            >
                                                <QueryField placeholder="modifier selection filter" type='Modifier' clientId={clientId} />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'sort']}
                                                style={{ width: 75 }}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="Sort" type='number' />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'objectSelector']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input readOnly disabled placeholder="Object selector" />
                                            </Form.Item>

                                            <Form.Item name={[name, 'isRequired']} valuePropName="checked">
                                                <Checkbox>Required</Checkbox>
                                            </Form.Item>
                                        </Space>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item label='Prop sets'>
                        <Form.List name="propsets">
                            {(fields) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <React.Fragment key={key}>
                                            <Space style={{ display: 'flex' }} align="baseline">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'label']}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input placeholder="Label" />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'name']}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input placeholder="name" />
                                                </Form.Item>

                                                <Form.Item name={[name, 'isRequired']} valuePropName="checked">
                                                    <Checkbox>Required</Checkbox>
                                                </Form.Item>
                                            </Space>


                                            <Form.Item label='Options' style={{ paddingLeft: 50 }}>
                                                <Form.List name={[name, 'options']}>
                                                    {(fields) => (
                                                        <>
                                                            {fields.map(({ key, name, ...restField }) => (
                                                                <Space key={key} style={{ display: 'flex' }} align="baseline">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'title']}
                                                                        rules={[{ required: true }]}
                                                                    >
                                                                        <Input placeholder="Title" />
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'objectSelector']}
                                                                        rules={[{ required: true }]}
                                                                    >
                                                                        <Input readOnly disabled placeholder="Object selector" />
                                                                    </Form.Item>
                                                                </Space>
                                                            ))}
                                                        </>
                                                    )}
                                                </Form.List>
                                            </Form.Item>

                                        </React.Fragment>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item label='Placement points'>
                        <Form.List name="placementpoints">
                            {(fields) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => {
                                        let useModelPack = form.getFieldValue(['placementpoints', name, 'useModelpack']);
                                        return (
                                            <Space key={key} style={{ display: 'flex' }} align="baseline">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'label']}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input placeholder="Label" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'objectSelector']}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input readOnly disabled placeholder="object selector" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'modelSelectionFilter']}
                                                >
                                                    <QueryField placeholder="model filter" type={useModelPack ? 'Modelpack' : 'Model'} clientId={clientId} />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    style={{ width: 75 }}
                                                    name={[name, 'sort']}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input placeholder="Sort" type='number' />
                                                </Form.Item>

                                                <Form.Item name={[name, 'isRequired']} valuePropName="checked">
                                                    <Checkbox>Required</Checkbox>
                                                </Form.Item>

                                                <Form.Item name={[name, 'useModelpack']} valuePropName="checked">
                                                    <Checkbox>Use&nbsp;modelpack</Checkbox>
                                                </Form.Item>
                                            </Space>
                                        )
                                    })}
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Form>
            </Drawer>
        </>

    );
};

export default EditScene;