import { Chart } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartTypeRegistry } from "chart.js/auto";
import "./StatisticsChart.scss";
import { ChartData } from '../../../openapi/requests';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';

const StatisticsChart: React.FC<{ labels: string[], chartData: ChartData, color?: string, displayData: string[] }> = ({labels, chartData, color, displayData}) => {

    return (
        <Chart className='statistics-chart'       
        type={chartData.type.toLowerCase() as keyof ChartTypeRegistry}
        data={{labels: labels, datasets: chartData.dataSet.filter(x => displayData.includes(x.identifier))}}
        options={{ 
            locale:'en-GB',
            responsive: true,
            layout: { padding: 20 },
            scales: {               
              y: {
                beginAtZero: true
              }              
            },          
            borderColor(ctx, options) {  
              if (color)
                return color;
            },
            backgroundColor(ctx, options) {   
              if (color)
               return color;
            } 
          }}          
        />
    )

}

export default StatisticsChart;