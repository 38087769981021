import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BASE_PATH } from '../..';
import { ReactComponent as LoadingIcon } from '../../assets/icons/loading.svg';
import { OrderlineFileType } from '../../models/enums';
import { OrderlineFile, ElasticProject } from '../../openapi/requests';
import './ProjectList.scss';
import { format } from 'date-fns';
import Overlay from './Overlay';
import { ProjectDetail } from '../project/ProjectDetail';

const LoadingComponent = motion(LoadingIcon)


type Props = {
    className?: string;
    projects: ElasticProject[];
    loading?: boolean;
}

type ProjectType = {
    project: ElasticProject;
    totalFiles: number;
    thumbnailFiles: OrderlineFile[];
}

const ProjectList = ({ className, projects, loading }: Props) => {    
    const [showProject, setShowProject] = useState(false);
    const [selectedProject, setSelectedProject] = useState(0);

    const classes = classnames('projectlist', className);

    const handleClick = useCallback((project: ElasticProject) => {
        setShowProject(true);
        setSelectedProject(project.id);
    }, []);

    const projectList: ProjectType[] = useMemo(() => {
        return projects.map(project => {
            return ({
                project,
                totalFiles: project.deliveredOrderlines.reduce((e, n) => e + (n.files?.length ?? 0), 0),
                thumbnailFiles: project.deliveredOrderlines
                    .filter(e => (e.files?.length ?? 0) > 0 && (e.fileType as number === OrderlineFileType.Still || e.fileType as number === OrderlineFileType.Cutout || e.fileType as number === OrderlineFileType.Spin))
                    .map(e => e.files[0])
                    .slice(0, 3)
            });
        })
    }, [projects]);

    return (
        <>
            <div className={classes}>
                <AnimatePresence>
                    {loading && <LoadingComponent
                        className={'loading'}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        key={'loading'} />}
                </AnimatePresence>

                {projectList.map(item => <div className='project'
                    onClick={() => handleClick(item.project)}
                    key={item.project.id}>
                    <div className={`project-image images-${item.thumbnailFiles.length}`}>
                        {item.thumbnailFiles.map(file => <LazyLoadImage src={`${BASE_PATH}${file.url + "/thumbnail/200/webp"}`} alt={file.name} />)}
                        {item.thumbnailFiles.length === 0 && <LazyLoadImage src={`${BASE_PATH}${item.project.thumbnail}`} alt={item.project.name} />}
                    </div>
                    <div className='project-footer'>
                        <div className='project-name'>
                            <span className='name'>{item.project.title ?? item.project.name}</span>
                            {item.totalFiles > 0 && <span className='files'>{item.totalFiles} files</span>}
                        </div>
                        <span className='date'>{format(new Date(item.project.timestampModify ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                    </div>
                </div>)}
            </div>

            <Overlay open={showProject} onClose={() => setShowProject(false)} className='project-detail-overlay'>
                <div className="project-detail-overlay-content">
                    <ProjectDetail projectId={selectedProject} />
                </div>
            </Overlay>
        </>
    )
};

export default ProjectList;