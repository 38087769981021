import React, { useContext, useCallback } from "react";
import { BASE_PATH } from "../..";
import { CartContext } from "../../contexts/CartContext";
import { SceneDesignerContext } from "../../contexts/SceneDesignerContext";
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { SceneCameraDTO } from "../../openapi/requests";
import { AuthContext } from "../../contexts/AuthContext";

interface ImageSelectorImage {
    cameraName: string;
    cameras: SceneCameraDTO[];
    selected: boolean;
    formats: {
        title: string;
        selected: boolean;
        zoomFactor: number;
        aspect: number;
        isEnabled: boolean;
    }[];
    variants: {
        title: string;
        name: string | undefined;
        selected: boolean;
        deliveryFilenameTemplate: string;
    }[];
}

const CameraSelector: React.FC<{ showAnimatedCameras: boolean, showStillCameras: boolean }> = ({ showAnimatedCameras, showStillCameras }) => {
    const { configuration, setConfiguration, template } = useContext(SceneDesignerContext);
    const { currentProject } = useContext(CartContext);
    const { hasPermission, hasRole } = useContext(AuthContext);

    const handleImageClick = useCallback((image: ImageSelectorImage) => {
        if (configuration.cameras.find(e => e.Name === image.cameraName)) {
            setConfiguration({ ...configuration, cameras: configuration.cameras.filter(e => e.Name !== image.cameraName) });
        } else {
            setConfiguration({ ...configuration, cameras: [...configuration.cameras, { cameras: image.cameras, Name: image.cameraName, Variants: [], Formats: [] }] });
        }
    }, [configuration, setConfiguration]);

    const handleFilenameChange = useCallback((image: ImageSelectorImage, variant: string | undefined, value: string) => {
        let cam = configuration.cameras.find(e => e.Name === image.cameraName);

        if (cam) {
            let camVariant = cam.Variants.find(e => e.Lighting === variant);

            if (camVariant) {
                camVariant = { ...camVariant, DeliveryFilenameTemplate: value };
                cam = { ...cam, Variants: [...cam.Variants.map(e => e.Lighting === variant ? camVariant! : e)] }
            }

            setConfiguration({ ...configuration, cameras: configuration.cameras.map(e => e.Name === image.cameraName ? cam! : e) });
        }

    }, [configuration, setConfiguration]);


    const handleVariantClick = useCallback((image: ImageSelectorImage, variant: string | undefined, title: string) => {
        let cam = configuration.cameras.find(e => e.Name === image.cameraName);
        if (cam) {
            if (cam.Variants.some(e => e.Lighting === variant)) {
                cam = { ...cam, Variants: cam.Variants.filter(e => e.Lighting !== variant) };
            } else {
                cam = { ...cam, Variants: [...cam.Variants, { Lighting: variant, DeliveryFilenameTemplate: (currentProject?.name + " " + cam.cameras[0].label + " " + title).trim() }] };
            }

            if (cam.Formats.length === 0 && cam.Variants.length === 0) {
                setConfiguration({ ...configuration, cameras: configuration.cameras.filter(e => e.Name !== image.cameraName) });
            } else {
                setConfiguration({ ...configuration, cameras: configuration.cameras.map(e => e.Name === image.cameraName ? cam! : e) });
            }

            setConfiguration({ ...configuration, cameras: configuration.cameras.map(e => e.Name === image.cameraName ? cam! : e) });
        } else {
            setConfiguration({
                ...configuration, cameras: [...configuration.cameras, {
                    cameras: image.cameras,
                    Name: image.cameraName,
                    Variants: [{ DeliveryFilenameTemplate: (currentProject?.name + " " + image.cameras[0].label + " " + title).trim(), Lighting: variant }],
                    Formats: [{ Aspect: image.formats[0].aspect, ZoomFactor: image.formats[0].zoomFactor }]
                }]
            });
        }
    }, [configuration, setConfiguration, currentProject]);

    const handleFormatClick = useCallback((image: ImageSelectorImage, aspect: number, zoomFactor: number) => {
        let cam = configuration.cameras.find(e => e.Name === image.cameraName);

        if (cam) {
            if (cam.Formats.some(e => e.Aspect === aspect)) {
                cam = { ...cam, Formats: cam.Formats.filter(e => e.Aspect !== aspect) };
            } else {
                cam = { ...cam, Formats: [...cam.Formats, { Aspect: aspect, ZoomFactor: zoomFactor }] };
            }

            if (cam.Formats.length === 0 && cam.Variants.length === 0) {
                setConfiguration({ ...configuration, cameras: configuration.cameras.filter(e => e.Name !== image.cameraName) });
            } else {
                setConfiguration({ ...configuration, cameras: configuration.cameras.map(e => e.Name === image.cameraName ? cam! : e) });
            }

        } else {
            setConfiguration({
                ...configuration, cameras: [...configuration.cameras, {
                    cameras: image.cameras,
                    Name: image.cameraName,
                    Variants: [{ DeliveryFilenameTemplate: (currentProject?.name + " " + image.cameras[0].label + " " + image.variants[0].title).trim(), Lighting: image.variants[0].name }],
                    Formats: [{ Aspect: aspect, ZoomFactor: zoomFactor }]
                }]
            });
        }
    }, [configuration, setConfiguration, currentProject]);

    if (!template) {
        return null;
    }

    let propset = template.scene.propsets.find(e => e.label.toLowerCase() === 'lighting');

    let cameras = template.scene.cameras;

    if (!showAnimatedCameras) {
        cameras = cameras.filter(e => !e.isAnimated);
    }
    if (!showStillCameras) {
        cameras = cameras.filter(e => e.isAnimated);
    }
    if (!hasPermission("RenderAnimatedCamera")) {
        cameras = cameras.filter(e => !e.isAnimated);
    }
    if (!hasRole("Cadesign")) {
        cameras = cameras.filter(e => e.isEnabled);
    }

    let cameraNames = cameras.map(e => e.cameraName);
    cameraNames = [...new Set(cameraNames)]

    let images: ImageSelectorImage[] = cameraNames.map(cameraName => {
        var formats = cameras.filter(e => e.cameraName === cameraName);

        return {
            cameras: formats,
            cameraName: cameraName,
            hasLighting: propset !== undefined && propset.options.length !== 0,
            selected: configuration.cameras.some(e => e.Name === cameraName),
            variants: propset?.options.map(val => ({
                title: val.title,
                name: val.name,
                selected: configuration.cameras.find(e => e.Name === cameraName)?.Variants.some(e => e.Lighting === val.name) || false,
                deliveryFilenameTemplate: configuration.cameras.find(e => e.Name === cameraName)?.Variants.find(e => e.Lighting === val.name)?.DeliveryFilenameTemplate ?? ''
            })) ?? [{
                title: '',
                name: undefined,
                selected: configuration.cameras.find(e => e.Name === cameraName)?.Variants.some(e => e.Lighting === undefined) || false,
                deliveryFilenameTemplate: configuration.cameras.find(e => e.Name === cameraName)?.Variants.find(e => e.Lighting === undefined)?.DeliveryFilenameTemplate ?? ''
            }],
            formats: formats.map(f => ({
                zoomFactor: f.zoomFactor,
                aspect: f.aspectRatio,
                title: (f.aspectRatio === 1 ? "Square" : (f.aspectRatio > 1 ? "Landscape" : "Portrait")),
                isEnabled: f.isEnabled,
                selected: configuration.cameras.find(e => e.Name === cameraName)?.Formats.some(e => e.Aspect === f.aspectRatio && e.ZoomFactor === f.zoomFactor) || false,
            }))
        }
    });

    return (

        <table>
            <colgroup>
                <col width={260} />
                <col width={180} />
                <col width={180} />
                <col />
                <col width={350} />
            </colgroup>
            <thead>
                <tr>
                    <th></th>
                    <th>Formats</th>
                    <th>Select Lighting</th>
                    <th>Render Name</th>
                    <th>Details</th>
                </tr>
                <tr>
                    <td colSpan={5} className='divider'>
                        <svg width="4000" height="2" viewBox="0 0 4000 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" d="M4000 1H0" stroke="#fff" strokeOpacity="0.6" strokeDasharray="2 2" />
                        </svg>
                    </td>
                </tr>
            </thead>
            <tbody>
                {images.map(image => <React.Fragment key={image.cameraName}>
                    <tr>
                        <td>
                            <div className={'camera' + (image.selected ? ' selected' : '')} onClick={() => handleImageClick(image)}>
                                <div className='checkmark'><CheckIcon /></div>
                                {image.cameras[0].isAnimated ?
                                    <video src={BASE_PATH + image.cameras[0].animationPreview} style={{ width: '100%' }} autoPlay loop muted></video> :
                                    <img src={BASE_PATH + image.cameras[0].thumbnail} style={{
                                        aspectRatio: image.cameras[0].aspectRatio,
                                        width: ((image.cameras[0].aspectRatio) >= 1) ? '100%' : '',
                                        height: ((image.cameras[0].aspectRatio) < 1) ? '100%' : ''
                                    }} alt={image.cameras[0].label} />
                                }
                            </div>
                        </td>
                        <td className='formats'>
                            {(image.formats.length === 1) ? <>
                                <label>Aspect</label>
                                {image.formats[0].aspect === 1 && <span key='SQ'>Square</span>}
                                {image.formats[0].aspect > 1 && <span key='LS'>Landscape</span>}
                                {image.formats[0].aspect < 1 && <span key='PT'>Portrait</span>}
                                {(!image.formats[0].isEnabled) && <label>Not enabled for customers</label>}
                            </> :
                                <>
                                    <div>
                                        {image.formats.map(e => <button key={e.aspect} onClick={() => handleFormatClick(image, e.aspect, e.zoomFactor)} className={'lighting' + (e.selected ? ' selected' : '')}>{e.title} {(!e.isEnabled) && <p className="warning">Not enabled for customers</p>}</button>)}
                                    </div>
                                </>}
                        </td>
                        <td className='variants'>
                            <div>
                                {image.variants.map(e => <button key={e.name} onClick={() => handleVariantClick(image, e.name, e.title)} className={'lighting' + (e.selected ? ' selected' : '')}>{e.title}</button>)}
                            </div>
                        </td>
                        <td className='filenames'>
                            <div>
                                {(!image.variants.some(e => e.selected)) ?
                                    <span className='placeholder'>You don’t have a configuration selected</span> :
                                    image.variants.map(variant => <div key={variant.name} className='deliveryFilename'>
                                        <input readOnly={!variant.selected} key={variant.name} type='text' value={variant.deliveryFilenameTemplate} placeholder='Type your image name here' onChange={e => handleFilenameChange(image, variant.name, e.target.value)} />
                                        <span className='error'>{(variant.selected && variant.deliveryFilenameTemplate === '') ? 'This field is mandatory' : null}</span>
                                    </div>)}
                            </div>
                        </td>
                        <td>
                            <label>{template.scene.title}</label>
                            {image.cameras[0].label}
                            {image.cameras[0].isAnimated && <>
                                <label>Duration</label>
                                <span>{(Math.round((image.cameras[0].animationEaseout - image.cameras[0].animationEasein) / 25))} seconds</span>
                            </>}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5} className='divider'>
                            <svg width="4000" height="2" viewBox="0 0 4000 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M4000 1H0" stroke="#fff" strokeOpacity="0.6" strokeDasharray="2 2" />
                            </svg>
                        </td>
                    </tr>
                </React.Fragment>)}
            </tbody>
        </table>
    )
};

export default CameraSelector;