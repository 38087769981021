import { FC, MouseEvent, MouseEventHandler, PropsWithChildren, ReactElement, useEffect, useState } from "react";
import "./ToggleButton.scss";

export interface ToggleButtonProps {
    className?: string,
    isToggled?: boolean,
    onIcon?: ReactElement | null,
    offIcon?: ReactElement | null,
    onToggle?: (toggled: boolean) => void
}

export const ToggleButton: FC<PropsWithChildren<ToggleButtonProps>> = ({ className, onIcon, offIcon, isToggled = false, onToggle, children }) => {

    const [toggled, setToggled] = useState<boolean>(isToggled);

    const onClick = (e: MouseEvent<HTMLButtonElement>) => {
        setToggled(!toggled)

        if (onToggle)
            onToggle(!toggled);
    }

    return (
        <>
            <button className={["toggle-button", toggled ? "active" : undefined, className].join(' ')} onClick={onClick}>
                <i>
                    {onIcon && toggled ?
                    onIcon
                    : null}
                    {offIcon && !toggled ?
                    offIcon
                    : null}
                </i>
                <span>{children}</span>
            </button>
        </>
    )
}

export default ToggleButton;