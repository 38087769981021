/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ElasticProjectODataListResponse } from '../models/ElasticProjectODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SearchProjectService {

    /**
     * @param q 
     * @param assets 
     * @param template 
     * @param mode 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ElasticProjectODataListResponse Success
     * @throws ApiError
     */
    public static getSearchProject(
q?: string,
assets?: Array<string>,
template?: number,
mode: string = 'or',
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ElasticProjectODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/Project',
            query: {
                'q': q,
                'assets': assets,
                'template': template,
                'mode': mode,
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

}
