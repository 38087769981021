import React, { ChangeEvent, Fragment, useContext, useMemo, useState } from 'react';
import 'chart.js/auto';
import { Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import StatisticsChart from './StatisticsChart';
import StatisticsFilter, { Filter } from '../../statistics/StatisticsFilter';
import "./StatisticsView.scss";
import { ConfigContext } from '../../../contexts/ConfigContext';
import { useStatisticsServiceGetStatistics, useStatisticsServiceGetStatisticsKey, useStatisticsServiceGetStatisticsUsage, useStatisticsServiceGetStatisticsUsageKey } from '../../../openapi/queries';
import dayjs from 'dayjs';
import StatisticsUsage from './StatisticsUsage';
import ToggleButton from '../../layout/elements/ToggleButton';

const StatisticsView: React.FC = () => {

    const { currentClient, odataFilters } = useContext(ConfigContext)

    const [filters, setFilters] = useState<Filter[]>([]);
    const [displayData, setDisplayData] = useState<string[]>(['current'])

    const [displayMode, setDisplayMode] = useState<string>("");

    const mappedFilters = useMemo(() => {

        let dateValues = filters.find(x => x.property === "date");
        let startDate = dayjs('2023-11-01')
        let endDate = dayjs().endOf("week");

        if (dateValues && dateValues.values.length > 0) {
            startDate = dayjs(dateValues.values[0]);

            if (dateValues.values.length > 1)
                endDate = dayjs(dateValues.values[1]);
        }

        let userValue = filters.find(x => x.property === "user");
        let userId = null;
        if (userValue) {
            userId = userValue.values[0].value;
        }

        let intervalValue = filters.find(x => x.property === "interval");
        let interval = "month";
        if (intervalValue) {
            interval = intervalValue.values[0].value;
        }

        let renderTypeValue = filters.find(x => x.property === "rendertype");
        let renderType = "final";
        if (renderTypeValue) {
            renderType = renderTypeValue.values[0].value;
        }

        if (displayMode === "lastyear") {
            startDate = dayjs().set("month", 1).startOf("month");
            endDate = dayjs().add(1, "year").set("month", 0).endOf("month");
            interval = "month";
            userId = null;
        }

        return {
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            dateInterval: interval,
            renderType: renderType,
            userId: userId,
            isEnabled: true,
            clientId: currentClient.id,
            displayMode: displayMode
        }

    }, [currentClient.id, displayMode, filters]);

    const { data: statistics, isFetched: statisticsFetched } = useStatisticsServiceGetStatistics({ ...mappedFilters }, [useStatisticsServiceGetStatisticsKey, mappedFilters], { refetchOnWindowFocus: false });
    const { data: usage, isFetched: usageFetched } = useStatisticsServiceGetStatisticsUsage({ ...mappedFilters }, [useStatisticsServiceGetStatisticsUsageKey, currentClient], { refetchOnWindowFocus: false });

    const dateIntervalOptions: DefaultOptionType[] = [
        { label: "Week", value: "week" },
        { label: "Month", value: "month" },
        { label: "Quarter", value: "quarter" },
        { label: "Year", value: "year" }
    ];

    const renderTypeOptions = [
        { label: "Preview", value: "preview" },
        { label: "Final", value: "final" },
    ]

    type StatisticsFilterProperties = {
        defaultValue?: string,
        clearable?: boolean;
        name: string,
        property: string,
        type: string,
        items: any;
    }

    let properties = Array<StatisticsFilterProperties>()

    properties = [
        { name: 'Date', property: 'date', type: 'daterange', items: [] },
        { name: 'Interval', property: 'interval', type: 'select', items: dateIntervalOptions, defaultValue: "month" },
        { name: 'Render Type', property: 'rendertype', type: 'select', items: renderTypeOptions },
    ];

    const onChange = (filters: Filter[]) => {
        var updatedFilters = filters.filter(x => x.values.length > 0);
        setFilters([...updatedFilters]);
    }

    const onShowLastYear = (toggled: boolean) => {

        let displayData = ["current"];
        let displayMode = ""
        if (toggled) {
            displayData.push("lastyear");
            displayMode = "lastyear";
        }
        setDisplayData(displayData)
        setDisplayMode(displayMode);

    }

    return (
        <div className='admin-statistics-page-view'>

            <Spin spinning={!usageFetched}>
                {usageFetched ? <StatisticsUsage items={usage} /> : null}
            </Spin>

            <div className='admin-statistics-filters-wrapper'>
                <StatisticsFilter isOpen={true} filters={filters} onChange={onChange} properties={properties} disabled={displayMode.length > 0} />
                <ToggleButton className='has-icon button secondary' onToggle={onShowLastYear}>Compare Last/Current Fiscal Year</ToggleButton>
            </div>

            <Spin spinning={!statisticsFetched}>
                {statisticsFetched ? <div className='admin-statistics-page-charts'>

                    <div className='admin-statistics-page-charts-body'>
                        <h1>Assets Generated</h1>
                        {(statistics?.value ?? []).filter(x => ['In-situ', 'Cut-out', '360° Spin', 'Video'].includes(x.name)).map(chart => (
                            <div className="admin-statistics-page-chart" key={chart.id}>
                                <div className='admin-statistics-page-chart-body'>
                                    {chart.data.map(chartData => (
                                        <div className="admin-statistics-chart-wrapper" key={chart.id + "_" + chartData.name}>
                                            <h2>{chartData.name}</h2>
                                            <StatisticsChart labels={chart.labels} chartData={chartData} color="#adadad" displayData={displayData} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='admin-statistics-page-charts-body'>
                        <h1>Assets Implemented</h1>
                        {(statistics?.value ?? []).filter(x => ['Scenes', 'Assets', 'Styling'].includes(x.name)).map(chart => (
                            <div className="admin-statistics-page-chart" key={chart.id}>
                                <div className='admin-statistics-page-chart-body'>
                                    {chart.data.map(chartData => (
                                        <div className="admin-statistics-chart-wrapper" key={chart.id + "_" + chartData.name}>
                                            <h2>{chartData.name}</h2>
                                            <StatisticsChart labels={chart.labels} chartData={chartData} color="#adadad" displayData={displayData} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='admin-statistics-page-charts-body'>
                        <h1>DAM Uploads</h1>
                        {(statistics?.value ?? []).filter(x => ['DAMUploads'].includes(x.name)).map(chart => (
                            <div className="admin-statistics-page-chart" key={chart.id}>
                                <div className='admin-statistics-page-chart-body'>
                                    {chart.data.map(chartData => (
                                        <div className="admin-statistics-chart-wrapper" key={chart.id + "_" + chartData.name}>
                                            <h2>{chartData.name}</h2>
                                            <StatisticsChart labels={chart.labels} chartData={chartData} color="#adadad" displayData={displayData} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='admin-statistics-page-charts-body'>
                        <h1>Projects</h1>
                        {(statistics?.value ?? []).filter(x => ['Projects'].includes(x.name)).map(chart => (
                            <div className="admin-statistics-page-chart" key={chart.id}>
                                <div className='admin-statistics-page-chart-body'>
                                    {chart.data.map(chartData => (
                                        <div className="admin-statistics-chart-wrapper" key={chart.id + "_" + chartData.name}>
                                            <h2>{chartData.name}</h2>
                                            <StatisticsChart labels={chart.labels} chartData={chartData} color="#adadad" displayData={displayData} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                    : null}
            </Spin>
        </div>
    )
}

export default StatisticsView;