import { FC } from "react";
import "./StatisticsUsage.scss";
import { UsageStatDTO } from "../../../openapi/requests";
import{ Progress } from "antd";

export interface StatisticsUsageProps {
    items: UsageStatDTO[]
}

const StatisticsUsage: FC<StatisticsUsageProps> = ({items}) => {

    const calculatePercent = (item:UsageStatDTO) => {
        return Math.round((item.currentUsage/item.usageLimit)*100);
    }

    return (
        <div className="statistics-usage">
             <h2>Actual usage vs. License Agreement</h2>
            {items.map(item => 
                <div key={item.name} className="statistics-usage-item">
                    <span>{item.name}</span>
                    <span>{`${item.currentUsage.toLocaleString('en-GB', { minimumFractionDigits: 0 })}/${item.usageLimit.toLocaleString('en-GB', { minimumFractionDigits: 0 })}`}</span>
                    <Progress percent={calculatePercent(item)} strokeLinecap="square" showInfo={(calculatePercent(item) > 100)}   percentPosition={{ align: 'center', type: 'inner' }} format={(percent) => "Limit reached"} strokeColor={(calculatePercent(item) < 100) ?"rgb(114, 114, 114)" : "rgb(0,0,0)"} size={{height: 30}} />
                </div>        
            )}
        </div>
    )
}

export default StatisticsUsage;