import format from "date-fns/format";
import { saveAs } from 'file-saver';
import { useCallback, useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BASE_PATH } from "../..";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as ExternalLink } from "../../assets/icons/external_link.svg";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { DialogContext } from "../../contexts/DialogContext";
import { OrderlineFileType } from "../../models/enums";
import { useOrderlineServicePostOrderOrderlineAddToSolutionizer, useOrderlineServicePostOrderOrderlineRequestManualAdjusments } from "../../openapi/queries";
import { OrderDTO, OrderlineFile, OrderlineViewDTO } from "../../openapi/requests";
import { Confirm } from "../layout/Dialog";
import { Turntable } from "../layout/elements/Turntable";
import "./OrderlineList.scss";

interface Props {
    orderlines: OrderlineViewDTO[];
    order?: OrderDTO;
}

export const OrderlineList: React.FC<Props> = ({ orderlines, order }) => {

    const { hasPermission, hasRole } = useContext(AuthContext);
    const { message, error } = useContext(DialogContext);

    const [isDownloading, setIsDownloading] = useState<number[]>([]);

    const { mutateAsync: requestManualAdjusments } = useOrderlineServicePostOrderOrderlineRequestManualAdjusments();
    const { mutateAsync: addToSolutionizer } = useOrderlineServicePostOrderOrderlineAddToSolutionizer();

    const [internalOrderlines, setInternalOrderlines] = useState<OrderlineViewDTO[]>([]);

    useEffect(() => {
        let lines = orderlines.filter(e => e.files.length > 0);

        if(!hasPermission("RenderAnimatedCamera")){
            lines = lines.filter(e => (e.fileType as unknown as OrderlineFileType) !== OrderlineFileType.Clip);
        }
        if(!hasPermission("RenderVideo")){
            lines = lines.filter(e => (e.fileType as unknown as OrderlineFileType) !== OrderlineFileType.Animation);
        }

        setInternalOrderlines(lines);
    }, [orderlines, hasPermission]);

    const onDownloadFile = (file: OrderlineFile) => {
        ReactGA.event('download_project_file', {});
        setIsDownloading(s => [...s, file.id]);

        fetch(`${BASE_PATH}${file.url}`, {
            method: 'get',
            headers: {
                Accept: file.contentType,
                'Content-Type': file.contentType
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob, file.name);
            setIsDownloading(s => s.filter(e => e !== file.id));
        });
    }

    const onDownloadFiles = (file: OrderlineFile) => {
        ReactGA.event('download_project_files', {});
        setIsDownloading(s => [...s, file.id]);

        let baseFileUrl = file.url.match(/.*(\/)/)!;

        fetch(`${BASE_PATH}${baseFileUrl[0]}all`, {
            method: 'get',
            headers: {
                Accept: file.contentType,
                'Content-Type': file.contentType
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
            setIsDownloading(s => s.filter(e => e !== file.id));
        });
    }

    const onRequestManualAdjusments = useCallback((orderline: OrderlineViewDTO) => {
        if (order) {
            ReactGA.event('request_manual_adjustments', {});

            requestManualAdjusments({ id: orderline.id, key: order.id })
                .then(e => {
                    message("Order moved to workflow", <a href={e.fullCommentURL} target="_blank" rel="noreferrer">Open in workflow <ExternalLink /></a>);
                    setInternalOrderlines(internalOrderlines.map(f => f.id === orderline.id ? { ...f, workflowUrl: e.fullCommentURL } : f));
                }).catch(e => {
                    error("An error occured", e);
                })
        }
    }, [error, internalOrderlines, message, order, requestManualAdjusments]);

    const onAddToSolutionizer = useCallback((orderline: OrderlineViewDTO) => {
        if (order) {
            ReactGA.event('add_to_solutionizer', {});

            addToSolutionizer({ id: orderline.id, key: order.id })
                .then(e => {
                    message("Order moved to workflow", <a href={e.fullAssetURL} target="_blank" rel="noreferrer">Open in workflow <ExternalLink /></a>);
                }).catch(e => {
                    error("An error occured", e);
                })
        }
    }, [addToSolutionizer, error, message, order]);



    const renderProjectFile = useCallback((orderline: OrderlineViewDTO) => {

        let workflowButton: React.ReactNode = null;
        let workflowButton2: React.ReactNode = null;

        if (order && hasPermission("RequestManualAdjustments")) {
            if (orderline.workflowUrl) {
                workflowButton = <a href={orderline.workflowUrl} target="_blank" rel="noreferrer">Open in workflow</a>;
            } else {
                workflowButton = <Confirm title="Request manual adjustments" text="This will copy the image to workflow, do you want to continue?" onConfirm={() => onRequestManualAdjusments(orderline)}>
                    <button className="rounded">Request manual adjustments</button>
                </Confirm>
            }
        }

        if (order && hasRole("Cadesign")) {
            if (orderline.solutionizerUrl) {
                workflowButton2 = <>
                    <a href={orderline.solutionizerUrl} target="_blank" rel="noreferrer">Open in solutionizer</a>
                    <Confirm title="Update in solutionizer" text="This will update the image in workflow, do you want to continue?" onConfirm={() => onAddToSolutionizer(orderline)}>
                        <button className="rounded">Update in solutionizer</button>
                    </Confirm>
                </>;
            } else {
                workflowButton2 = <Confirm title="Add to solutionizer" text="This will copy the image to workflow, do you want to continue?" onConfirm={() => onAddToSolutionizer(orderline)}>
                    <button className="rounded">Add to solutionizer</button>
                </Confirm>
            }
        }

        switch (orderline.fileType as unknown as OrderlineFileType) {
            case OrderlineFileType.Spin:
                return <>
                    <div className="project-images-list-img no-transition">
                        <Turntable sensitivity={0.3} images={orderline.files.map(file => `${BASE_PATH}${file.url + "/thumbnail/800/png"}`)} />
                    </div>
                    <div className="project-images-list-name">
                        <span>{orderline.files[0].name}</span>
                    </div>
                    <div className="project-images-list-actions">
                        {workflowButton}
                        {workflowButton2}
                        <button onClick={(e) => onDownloadFiles(orderline.files[0])}>
                            {(isDownloading.includes(orderline.files[0].id)) ? <div className="loading"><Loading /></div> : <Download />}
                        </button>
                    </div>
                    <div className="project-images-list-date">
                        <span>{format(new Date(orderline.files[0].timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                    </div>
                </>
            case OrderlineFileType.Animation:
            case OrderlineFileType.Clip:
                let file = orderline.files[0];
                return <>
                    <div className="project-images-list-img">
                        <video src={`${BASE_PATH}${file.url}`} controls />
                    </div>
                    <div className="project-images-list-name">
                        <span>{orderline.files[0].name}</span>
                    </div>
                    <div className="project-images-list-actions">
                        {workflowButton}
                        {workflowButton2}
                        <button onClick={(e) => onDownloadFiles(orderline.files[0])}>
                        {(isDownloading.includes(orderline.files[0].id)) ? <div className="loading"><Loading /></div> : <Download />}
                        </button>
                    </div>
                    <div className="project-images-list-date">
                        <span>{format(new Date(orderline.files[0].timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                    </div>
                </>
            case OrderlineFileType.Cutout:
            default: {
                let file = orderline.files[0];

                return <>
                    <div className="project-images-list-img">
                        <LazyLoadImage src={`${BASE_PATH}${file.url + "/thumbnail/800/png"}`} alt={file.name} />
                    </div>
                    <div className="project-images-list-name">
                        <span>{file.name}</span>
                    </div>
                    <div className="project-images-list-actions">
                        {workflowButton}
                        {workflowButton2}
                        <button onClick={(e) => onDownloadFile(file)}>
                            {(isDownloading.includes(orderline.files[0].id)) ? <div className="loading"><Loading /></div> : <Download />}
                        </button>
                    </div>
                    <div className="project-images-list-date">
                        <span>{format(new Date(file.timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                    </div>
                </>

            }
        }
    }, [hasPermission, hasRole, isDownloading, onAddToSolutionizer, onRequestManualAdjusments, order])

    return (
        <ul className="orderlineList">
            {internalOrderlines?.map(line =>
                <li key={line.id}>
                    {renderProjectFile(line)}
                </li>
            )}
        </ul>
    )
}