import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { CombinedResultDTO, SceneCameraDTO, TemplateViewDTO } from "../../../openapi/requests";
import { genericAnglesIds, templateTypeIds } from "../../../pages/CutoutsPage";
import { ReactComponent as FacingLeftAngle } from "../../../assets/icons/_c_01c.svg";
import { ReactComponent as StraightOnAngle } from "../../../assets/icons/_c_02c.svg";
import { ReactComponent as SideAngle } from "../../../assets/icons/_c_05c.svg";
import { ReactComponent as DetailShotAngle } from "../../../assets/icons/_c_36c.svg";
import { ReactComponent as Spin360 } from "../../../assets/icons/spin360.svg";
import CheckBox from "../../layout/CheckBox";
import { getRenderTemplates } from "../../../contexts/CartContext";
import { CutoutAngle, CutoutCategory } from "../../../types/types";

interface AngleSelectorProps {
    assets: CombinedResultDTO[],
    templates: TemplateViewDTO[],
    onAnglesChange: (selectedAngle: CutoutAngle[]) => void
}

export const AngleSelector: FC<AngleSelectorProps> = ({ assets, templates, onAnglesChange }) => {

    const [availableAngles, setAvailableAngles] = useState<CutoutAngle[]>([]);
    const [selectedAngles, setSelectedAngles] = useState<CutoutAngle[]>([]);

    const availableTemplates = useMemo(() => {
        return templates.filter(e => e.editorModule.toLowerCase() === "cutout" || e.templatetype.internalName === "3dsmax_360spin") ?? [];
    }, [templates])

    const uniqueAngles = useMemo(() => {
        return availableAngles.filter(a => genericAnglesIds.includes(a.id.toLowerCase())).filter((value, index, arr) => arr.map(x => x.id).indexOf(value.id) === index);
    }, [availableAngles])

    const uniqueCategories = useMemo(() => {
        const angles = availableAngles.filter(a => a.template.templatetype.internalName !== "3dsmax_360spin" && !genericAnglesIds.includes(a.id.toLowerCase()) && !templateTypeIds.includes(a.id.toLowerCase()));
        const uniqueCategories = angles.map(x => x.template!).filter((value, index, arr) => arr.indexOf(value) === index);
        return uniqueCategories.map(x => { return { id: x.id, name: x.title, angles: angles.filter(a => a.template?.id === x.id).filter((value, index, arr) => arr.map(x => x.id).indexOf(value.id) === index) } as CutoutCategory })

    }, [availableAngles]);

    const uniqueTemplateTypes = useMemo(() => {
        return availableAngles.filter(a => a.template.templatetype.internalName !== "3dsmax_still" && templateTypeIds.includes(a.id.toLowerCase())).filter((value, index, arr) => arr.map(x => x.id).indexOf(value.id) === index);
    }, [availableAngles]);

    const getCameraTypeIcon = (id: string) => {

        switch (id.toLowerCase()) {
            case "c_01c":
                return <FacingLeftAngle />
            case "c_02c":
                return <StraightOnAngle />
            case "c_05c":
                return <SideAngle />
            case "c_36c":
                return <DetailShotAngle />
            case "c_01s":
                return <Spin360 />
        }
    }

    const formatAngleName = (title: string) => {
        return title.toLowerCase().replace("c_", "");
    }

    const formatCategoryName = (title: string) => {
        return title.replace("CoD_CutOut_", "")
    }

    const getDeliveryFileNameTemplate = useCallback((sceneCamera: SceneCameraDTO) => {
        let cameraName = formatAngleName(sceneCamera.cameraName)
        switch (cameraName) {
            //Spin360
            case "01s":
                return "{{SPINNO}}s";
            default:
                return cameraName;
        }
    }, [])

    useEffect(() => {

        let angles: CutoutAngle[] = [];
        assets.forEach(asset => {

            const renderTemplates = getRenderTemplates(availableTemplates, asset);
            if (renderTemplates) {

                for (let i = 0; i < renderTemplates.length; i++) {
                    let renderTemplate = renderTemplates[i];
                    if (renderTemplate) {

                        renderTemplate?.scene.cameras.forEach(cam => {

                            var angleValid = false;
                            if (renderTemplate?.templatetype.internalName === "3dsmax_360spin") {
                                if (cam.cameraName.toLowerCase() === "c_01s") {
                                    angleValid = true
                                }
                            }

                            if (renderTemplate?.templatetype.internalName === "3dsmax_still") {
                                if (cam.cameraName.toLowerCase() !== "c_01s") {
                                    angleValid = true
                                }
                            }

                            if (angleValid) {
                                let angle: CutoutAngle = { uniqueId: cam.cameraName.toLowerCase() + "|" + renderTemplate?.id, id: cam.cameraName, name: cam.label, template: renderTemplate!, deliveryFileNameTemplate: getDeliveryFileNameTemplate(cam), aspect: cam.aspectRatio, zoomFactor: cam.zoomFactor};
                                angles.push(angle);
                            }

                        });

                    }
                }
            }
        });

        setAvailableAngles(angles.filter((value, index, arr) => { return arr.map(x => x.uniqueId).indexOf(value.uniqueId) === index }));

    }, [assets, availableTemplates, getDeliveryFileNameTemplate])

    const onAngleSelectAll = (checked: boolean) => {

        let uniqueAvailableAngles: CutoutAngle[] = [];
        if (checked) {
            uniqueAvailableAngles = availableAngles.filter((value, index, arr) => { return arr.map(x => x.uniqueId).indexOf(value.uniqueId) === index });
        }

        setSelectedAngles(uniqueAvailableAngles);
        onAnglesChange(uniqueAvailableAngles);
        //  debouncedSave();
    }

    const onAngleSelect = (checked: boolean, angle: CutoutAngle) => {

        let angles: CutoutAngle[] = [];
        let newAngles: CutoutAngle[] = [];

        if (genericAnglesIds.includes(angle.id.toLowerCase()) || templateTypeIds.includes(angle.id.toLowerCase())) {
            angles = selectedAngles.filter(x => x.id.toLowerCase() !== angle.id.toLowerCase());
            newAngles = (checked) ? availableAngles.filter(x => x.id.toLowerCase() === angle.id.toLowerCase()) : [];
        } else {
            angles = selectedAngles.filter(x => !(x.id.toLowerCase() === angle.id.toLowerCase() && x.template?.id === angle.template?.id));
            newAngles = (checked) ? availableAngles.filter(x => x.id.toLowerCase() === angle.id.toLowerCase() && x.template?.id === angle.template?.id) : [];
        }

        angles = [...angles, ...newAngles];
        angles = angles.filter((value, index, arr) => { return arr.map(x => x.uniqueId).indexOf(value.uniqueId) === index })

        setSelectedAngles(angles);
        onAnglesChange(angles);
        //  debouncedSave();
    }

    return (
        <div className="angle-selector">
            <div className="info-list cutout-detail-list cutout-detail-angles">
                <div className="info-list-header"><span>Angles</span></div>
                <ul>{availableAngles.length > 0 ?
                    <>
                        <li>
                            <CheckBox label="Select All Angles" checked={false} onChange={onAngleSelectAll} />
                        </li>
                        <li>
                            <div className="cutout-angles">
                                {uniqueAngles.filter(a => genericAnglesIds.includes(a.id.toLowerCase())).map(a =>
                                    <div key={a.uniqueId} className={`cutout-angle ${selectedAngles.map(x => x.id.toLowerCase()).includes(a.id.toLowerCase()) ? 'checked' : ''}`}>
                                        <CheckBox label={""} checked={selectedAngles.filter(sa => sa.id.toLowerCase() === a.id.toLowerCase() && sa.template?.id === a.template?.id).length > 0} onChange={(checked) => onAngleSelect(checked, a)}>
                                            <div>
                                                <span>{getCameraTypeIcon(a.id)}</span>
                                                <p>
                                                    <span>{formatAngleName(a.id)}</span>
                                                    <span>{a.name}</span>
                                                </p>
                                            </div>
                                        </CheckBox>
                                    </div>
                                )}
                            </div>
                        </li>
                        {uniqueCategories.map(category =>
                            <>
                                <li className="info-list-header cutout-detail-list-category">
                                    {formatCategoryName(category.name)}
                                </li>
                                {category.angles.map(a =>
                                    <li key={a.uniqueId}>
                                        <CheckBox label={""} checked={selectedAngles.filter(sa => sa.id.toLowerCase() === a.id.toLowerCase() && sa.template?.id === a.template?.id).length > 0} onChange={(checked) => onAngleSelect(checked, a)}>
                                            <div>
                                                <span>{formatAngleName(a.id)}</span>
                                                <span>{a.name}</span>
                                            </div>
                                        </CheckBox>
                                    </li>
                                )}
                            </>
                        )}
                        <li>
                            <div className="cutout-templatetypes">
                                {uniqueTemplateTypes.filter(a => templateTypeIds.includes(a.id.toLowerCase())).map(a =>
                                    <div key={a.uniqueId} className={`cutout-templatetype ${selectedAngles.map(x => x.id.toLowerCase()).includes(a.id.toLowerCase()) ? 'checked' : ''}`}>
                                        <CheckBox label={""} checked={selectedAngles.filter(sa => sa.id.toLowerCase() === a.id.toLowerCase() && sa.template?.id === a.template?.id).length > 0} onChange={(checked) => onAngleSelect(checked, a)}>
                                            <div>
                                                <span>{getCameraTypeIcon(a.id)}</span>
                                                <p>
                                                    <span>{formatAngleName(a.id)}</span>
                                                    <span>{a.name}</span>
                                                </p>
                                            </div>
                                        </CheckBox>
                                    </div>
                                )}
                            </div>
                        </li>

                    </>
                    : <li className="noangles">No availble angles...</li>}
                </ul>
            </div>
        </div>
    )
}